import React, { useEffect, useState } from 'react'
import { Flex, Box, Text } from 'rebass'
import Heading from '../../elements/Heading'
import StyledWhyUsTeam from './style'
import TeamMember from '../../elements/TeamMember'
import FadeIn from '../../elements/FadeIn'

const WhyUsTeam = ({ teamMembers, teamTitle, teamSubtitle }) => {
  const [chosen, setChosen] = useState()
  const [isDesktop, setIsDesktop] = useState(true)

  const screenSizeCheck = () => {
    if (window.innerWidth < 992) {
      setIsDesktop(false)
    } else {
      setIsDesktop(true)
    }
  }

  useEffect(() => {
    screenSizeCheck()

    window.addEventListener('resize', screenSizeCheck)

    return () => {
      window.removeEventListener('resize', screenSizeCheck)
    }
  }, [])

  return (
    <StyledWhyUsTeam mx="auto" mb={7} px={[4, 4, 4, 4, 0]}>
      <Box className="team-intro">
        <Box width={[1, 1, 1, 1, 27 / 32]}>
          <Heading fontSize={[4, 4, 4, 5]} postsymbol="">{teamTitle}</Heading>
        </Box>
        <Box width={[1, 1, 1, 1, 27 / 32]} />
        <FadeIn>
          <Text className="eyebrow" fontSize={[1, 1, 1, 2]} fontWeight="300" mt={5}>
            {teamSubtitle}
          </Text>
        </FadeIn>
      </Box>
      <Flex className="team-members" flexWrap="wrap" my={[5]}>
        {teamMembers.map(teamMember => (
          <TeamMember
            active={teamMember === chosen}
            bio={teamMember.bio.bio}
            count={teamMember}
            jobTitle={teamMember.jobTitle}
            key={teamMember.id}
            id={teamMember.id}
            name={teamMember.name}
            onMouseOver={() => isDesktop && setChosen(teamMember)}
            onFocus={() => isDesktop && setChosen(teamMember)}
            onClick={() => !isDesktop && setChosen(teamMember)}
            quote={teamMember.quote}
            thumbnail={teamMember.thumbnail}
          />
        ))}
      </Flex>
    </StyledWhyUsTeam>
  )
}

export default WhyUsTeam
