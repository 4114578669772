import React from 'react'
import { Text } from 'rebass'

const CardDetail = ({ cardLabel, children }) => (
  <>
    <Text className="card-label" fontWeight="900" mt={1} textAlign="center">{cardLabel}</Text>
    <Text textAlign="center">{children}</Text>
  </>
)

export default CardDetail
