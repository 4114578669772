import React, { useEffect, useState, useRef } from 'react'
import AOS from 'aos'
import { Flex, Box, Text } from 'rebass'
import ReactMarkdown from 'react-markdown'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import Card from '../../elements/Card'
import DigitalProduct from '../../elements/DigitalProduct'
import Value from '../../elements/Value'
import WhyUsCards from '../WhyUsCards'
import WhyUsService from '../WhyUsService'
import { StyledWhyUsTabs, SlidingBG, DarkMode } from './style'

const serviceTabIndexes = {
  'service-design': 0,
  'product-development': 1,
  'web-design': 2
}

const WhyUsTabs = ({ tabs, location }) => {
  const getQueryParams = () => {
    if (!location.search) return undefined
    return location.search.substr(1).split('&').reduce((acc, param) => {
      const [key, value] = param.split('=')
      return { ...acc, [key]: value }
    }, {})
  }

  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [previouslyActiveTabIndexes, setPreviouslyActiveTabIndexes] = useState([true, false, false])
  const [hasPendingScroll, setHasPendingScroll] = useState(false)

  const leftTabLearnMore = useRef(null)
  const centerTabLearnMore = useRef(null)
  const rightTabLearnMore = useRef(null)
  const learnMoreRefs = [leftTabLearnMore, centerTabLearnMore, rightTabLearnMore]

  const scrollTab = () => {
    document.querySelector('.react-tabs__tab-panel--selected').scrollIntoView({ behavior: 'smooth', alignToTop: '' })
  }

  const scrollToLearnMore = () => {
    window.location.hash = '#curious'
  }

  useEffect(() => {
    const params = getQueryParams()
    if (params && params.service && typeof serviceTabIndexes[params.service] !== 'undefined') {
      if (serviceTabIndexes[params.service] === activeTabIndex) {
        scrollToLearnMore()
      } else {
        setActiveTabIndex(serviceTabIndexes[params.service])
        setHasPendingScroll(true)
      }
    }
  }, [location.search])

  useEffect(() => {
    if (hasPendingScroll && learnMoreRefs[activeTabIndex].current) {
      scrollToLearnMore()
      setHasPendingScroll(false)
    }
  })

  const onTabChange = (tabIndex) => {
    setActiveTabIndex(tabIndex)

    setTimeout(() => {
      AOS.refresh()
    }, 100)

    if (!previouslyActiveTabIndexes[tabIndex]) {
      scrollTab()
      setPreviouslyActiveTabIndexes(tabIndexes => tabIndexes.map((previouslyActive, index) => previouslyActive || index === tabIndex))
    }
  }

  useEffect(() => {
    const whyUsTabs = document.querySelector('.why-us-tabs-wrapper')

    window.addEventListener('scroll', () => {
      if (window.scrollY > 3000) {
        whyUsTabs.classList.add('scrolled-past')
      } else if (whyUsTabs) {
        whyUsTabs.classList.remove('scrolled-past')
      }
    })

    setTimeout(() => {
      AOS.refresh()
    }, 1000)
  })

  const markdownRenderers = {
    heading: ({ children }) => <Text fontSize={[1, 1, 1, 2]} fontWeight="700" is="h3">{children}</Text>
  }

  return (
    <StyledWhyUsTabs
      className="tabs-wrapper"
      mx="auto"
      px={[4, 4, 4, 4, 0]}
      mb={[5, 5, 7, 7]}
    >
      <Tabs
        selectedIndex={activeTabIndex}
        onSelect={tabIndex => onTabChange(tabIndex)}
      >
        <Box
          className="why-us-tabs-wrapper"
          data-aos="fixedTabs"
          data-aos-anchor=".tabs-wrapper"
          data-aos-mirror="true"
          data-aos-anchor-placement="top-top"
          data-aos-duration="0"
          data-aos-offset="71"
          mx="auto"
          my={[5, 5, 0, 0]}
        >
          <TabList className="why-us-tabs">
            <SlidingBG position={activeTabIndex} />
            <Tab data-test-id="service-design-tab">
              <span>01.</span>
              {' '}
              <span>Service Design</span>
            </Tab>
            <Tab data-test-id="product-design-tab">
              <span>02.</span>
              {' '}
              <span>Product Development</span>
            </Tab>
            <Tab data-test-id="web-design-tab">
              <span>03.</span>
              {' '}
              <span>Web Design</span>
            </Tab>
          </TabList>
        </Box>
        {tabs.map((tab, tabIndex) => (
          <TabPanel key={tab.id}>
            <WhyUsService
              title={tab.tabTitle}
              quoteLogo={tab.tabTestimonialLogo}
              quoteText={tab.tabTestimonialQuote.tabTestimonialQuote}
              quoteThumbnail={tab.tabTestimonialThumbnail}
              quoteName={tab.tabTestimonialName}
              quotePosition={tab.tabTestimonialJobTitle}
              quoteLinkText={tab.tabTestimonialLinkText}
              quoteLinkPath={tab.tabTestimonialLinkPath}
              mx={3}
            >
              <Text className="eyebrow" fontSize={[1, 1, 1, 2]} mt={5}>
                <ReactMarkdown renderers={markdownRenderers}>{tab.tabDescription.tabDescription}</ReactMarkdown>
              </Text>
              {tab.tabDescriptionSplitLeft && (
                <Flex flexWrap="wrap" justifyContent="space-between" mt={[0, 0, 0, 5]}>
                  <Box width={[1, 1, 1, '45%']}>
                    <Text fontSize={[1, 1, 1, 2]}>
                      <ReactMarkdown renderers={markdownRenderers}>{tab.tabDescriptionSplitLeft.tabDescriptionSplitLeft}</ReactMarkdown>
                    </Text>
                  </Box>
                  {tab.tabDescriptionSplitRight && (
                    <Box width={[1, 1, 1, '45%']}>
                      <Text fontSize={[1, 1, 1, 2]}>
                        <ReactMarkdown renderers={markdownRenderers}>{tab.tabDescriptionSplitRight.tabDescriptionSplitRight}</ReactMarkdown>
                      </Text>
                    </Box>
                  )}
                </Flex>
              )}
              <Flex flexWrap="wrap" justifyContent="space-evenly">
                {tab.tabCaseStudies.map(caseStudy => (
                  <DigitalProduct
                    caseStudyClass={caseStudy.caseStudyClass}
                    caseStudyCta={caseStudy.caseStudyLinkText}
                    caseStudyImage={caseStudy.caseStudyImage}
                    caseStudyPath={caseStudy.caseStudyLinkPath}
                    key={caseStudy.id}
                  />
                ))}
              </Flex>
              <Flex
                className="values-wrapper"
                flexWrap="wrap"
                mb={5}
                justifyContent="center"
              >
                {tab.tabValues.map(value => (
                  <Value
                    key={value.id}
                    description={value.subtitle}
                    icon={value.icon}
                    title={value.title}
                  />
                ))}
              </Flex>
            </WhyUsService>
            <WhyUsCards
              title={tab.tabCardsTitle}
              subtitle={tab.tabCardsSubtitle.tabCardsSubtitle}
              learnMoreRef={learnMoreRefs[tabIndex]}
            >
              <DarkMode className="dark-mode-trigger" />
              {tab.tabCards.map((card, cardIndex) => (
                <Card
                  cardDetails={card.cardDetails}
                  icon={card.icon}
                  key={card.id}
                  subtitle={card.subtitle.subtitle}
                  title={card.title}
                  serviceLength={card.serviceLength}
                  testId={cardIndex + 1}
                />
              ))}
            </WhyUsCards>
          </TabPanel>
        ))}
      </Tabs>
    </StyledWhyUsTabs>
  )
}

export default WhyUsTabs
