import React from 'react'
import {
  Flex, Box, Image, Text
} from 'rebass'
import Button from '../../elements/Button'
import FadeIn from '../../elements/FadeIn'
import Heading from '../../elements/Heading'
import StyledWhyUsProduct from './style'

import 'react-tabs/style/react-tabs.css'

const WhyUsProduct = ({
  children, quoteLogo, quoteName, quoteText, quotePosition, quoteLinkText, quoteLinkPath, title
}) => (
  <StyledWhyUsProduct mb={[5, 5, 7, 7]} mx={[0, 0, 3, 3]}>
    <Box>
      <Heading fontSize={[3, 3, 4, 4]} is="h2" postsymbol="">{title}</Heading>
    </Box>
    {children}
    <Box mt={[7, 0, 0, 0]} mx="auto" width={[1, 1, 15 / 16, 3 / 4]}>
      <Image className="quote-logo" alt={quoteLogo.description} mx="auto" src={quoteLogo.file.url} />
      <FadeIn>
        <Text
          mt={5}
          fontSize={[1, 1, 1, 2]}
          fontWeight="700"
          lineHeight={['30px', '30px', '40px', '40px']}
          textAlign="center"
        >
          {quoteText}
        </Text>
      </FadeIn>
    </Box>
    <Box my={5}>
      <FadeIn delay="150">
        <Text
          fontSize={['16px', '16px', '16px', 1]}
          fontWeight="900"
          lineHeight={['24px', '24px', '24px', '28px']}
          mt={2}
          textAlign="center"
        >
          {quoteName}
        </Text>
        <Text fontSize={['16px', '16px', '16px', 1]} lineHeight={['24px', '24px', '24px', '28px']} mb={[4]} mt={1} textAlign="center">
          {quotePosition}
        </Text>
      </FadeIn>
    </Box>
    <Flex justifyContent="center">
      <a
        target="_blank"
        href={quoteLinkPath}
        rel="noopener noreferrer"
      >
        <Button
          className="more-reviews"
          variant="inverted"
        >
          {quoteLinkText}
        </Button>
      </a>
    </Flex>
  </StyledWhyUsProduct>
)

export default WhyUsProduct
