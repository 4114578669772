import Img from 'gatsby-image'
import React, { useEffect } from 'react'
import { Box, Text, Flex } from 'rebass'
import StyledTeamMember from './style'

import Heading from '../Heading'

const TeamMember = ({
  active, onClick, onMouseOver, id, thumbnail, name, jobTitle, bio, quote
}) => {
  useEffect(() => {
    const element = document.getElementById(id)

    if (active && window.innerWidth < 992) {
      setTimeout(() => { element.scrollIntoView({ behavior: 'smooth', alignToTop: '' }) }, 200)
    }
  }, [active, id])

  return (
    <StyledTeamMember className={active ? 'team-member hovered' : 'team-member'} p={3} onMouseOver={onMouseOver} onFocus={onMouseOver} onClick={onClick} width={[1, 1, 1, 1 / 3]}>
      <span className="team-anchor" id={id} />
      {/* <Image className='thumbnail' alt={thumbnail.description} mb={4} mx='auto' src={thumbnail.file.url}/> */}
      <Img
        alt={thumbnail.description}
        className="thumbnail"
        fluid={{
          ...thumbnail.fluid
        }}
      />
      <Flex>
        <Box alignSelf="flex-end">
          <Heading fontSize={[2]} is="h3" postsymbol="" width={1}>{jobTitle}</Heading>
          <Heading fontSize={[2]} is="h3" postsymbol="" width={1}>{name}</Heading>
        </Box>
      </Flex>
      <Flex className="hidden-content" flexDirection="column" justifyContent="space-between" p={2}>
        <Box>
          <Heading fontSize={[2, 2, 3, 3]} is="h3" mb={[2]} postsymbol="" width={1}>{`“${quote}”`}</Heading>
        </Box>
        <Box>
          <Heading fontSize={[1, 1, 2, 2]} is="h4" postsymbol="" width={1}>{name}</Heading>
          <Text fontSize={[0, 0, 1, 1]} fontWeight="bold" mb={2}>{jobTitle}</Text>
          <Text className="bio" fontSize={[0, 0, 0, 1]}>{bio}</Text>
        </Box>
      </Flex>
    </StyledTeamMember>
  )
}

export default TeamMember
