import React from 'react'
import { Flex, Box, Text } from 'rebass'
import Heading from '../../elements/Heading'
import { StyledWhyUsCards, LearnMoreScrollAnchor } from './style'

const WhyUsCards = ({ children, title, subtitle, learnMoreRef }) => (
  <StyledWhyUsCards pb={[8, 8, 9, 9]} pt={[5, 5, 6, 6]} mx="auto" data-test-id="why-us-cards">
    <LearnMoreScrollAnchor ref={learnMoreRef} id="curious" />
    <div className="card-bg" />
    <Box className="content" mx="auto">
      <Box mx={[0, 0, 3, 3]}>
        <Heading color="#fff" fontSize={[4, 4, 4, 5]} is="h2" postsymbol="" textAlign="left">{title}</Heading>
        <Text className="eyebrow" color="#fff" fontSize={[1, 1, 1, 2]} mt={5}>{subtitle}</Text>
      </Box>
      <Flex alignItems="flex-start" flexDirection={['column', 'column', 'column', 'row']} flexWrap="wrap" mt={4} justifyContent="space-between">
        {children}
      </Flex>
    </Box>
  </StyledWhyUsCards>
)

export default WhyUsCards
