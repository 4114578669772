import styled from 'styled-components'
import { Flex } from 'rebass'

const StyledCard = styled.div`
  background: #fff;
  border-radius: 20px;
  border: 2px solid white;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 373px;
  height: 465px;
  line-height: 20px;
  padding: 30px;
  position: relative;
  text-align: center;
  transition: 0.3s cubic-bezier(0.45, 0, 0.55, 1);
  transform-origin: top;
  width: 100%;
  z-index: 2;

  @media (min-width: 374px) {
    font-size: 18px;
    line-height: 22px;
    height: 420px;
  }

  @media (min-width: 413px) { 
    height: 410px;
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    height: 481px;
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    margin: 0;
    margin-bottom: 0;
    max-width: 31.75%;

    &:hover {
      transform: scale(1.05);
    }
  }

  @media (min-width: ${props => props.theme.breakpoints[3]}) {
    height: 487px;
    max-width: 355px;
  }

  * {
    color: #000;
  }


  a {
    box-sizing: border-box;
    width: 100%;
  }

  .card-content {
    pointer-events: none;
  }

  .dropdown-btn {
    margin-left: auto;
    margin-right: -10px;
    margin-top: -10px;
    min-height: 50px;
    transform: rotate(180deg);

    img {
      margin-bottom: 2px;
    }
  }

  h3 {
    font-weight: 900;
    letter-spacing: -2px;
    padding: 0;

    @media (min-width: ${props => props.theme.breakpoints[3]}) {
      padding: 0 27px;
    }
  }

  .icon-wrapper {
    height: 140px;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      height: 209px;
    }

    img {
      max-height: 140px;

      @media (min-width: ${props => props.theme.breakpoints[1]}) { 
        max-height: 209px;
      }
    }
  }

  .hidden-content,
  li {
    text-align: left;
  }

  .hidden-content {
    opacity: 0;
    transition: 0.3s cubic-bezier(0.45, 0, 0.55, 1); 

    ${Flex} {
      min-height: 361px;

      @media (min-width: 374px) { 
        min-height: 327px;
      }

      @media (min-width: ${props => props.theme.breakpoints[1]}) { 
        min-height: 294px;
      }
    }

    div {
      color: #000;
    }

    &.hidden {
      opacity: 0;
    }

    &.visible {
      opacity: 1;
      transition-delay: 0.3s;
    }
  }

  .visible-content {
    min-height: 187px;

    @media (min-width: 374px) { 
      min-height: 155px;
    }

    @media (min-width: 413px) { 
      min-height: 138px;
    }

    @media (min-width: ${props => props.theme.breakpoints[1]}) { 
      min-height: 155px;
    }

    @media (min-width: ${props => props.theme.breakpoints[3]}) { 
      min-height: 150px;
    }

    div {
      color: #000;
    }
  }

  .card-label {
    text-transform: uppercase;
  }

  &.closed {
    transition-property: height, transform, background;
    transition-delay: 0.3s, 0s, 0s;
  }

  &.open {
    height: auto;
    min-height: 0;
    max-width: none;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      height: 809px;
      max-width: 373px;
    }

    @media (min-width: ${props => props.theme.breakpoints[3]}) {
      height: 811px;
    }

    .card-content {
      pointer-events: auto;
    }

    ${Flex} {
      &:fist-of-type {
        height: 783px;
  
        @media (min-width: 374px) { 
          height: 733px;
        }
    
        @media (min-width: 413px) { 
          height: 687px;
        }
  
        @media (min-width: ${props => props.theme.breakpoints[1]}) {
          height: 777px;
        }
  
        @media (min-width: ${props => props.theme.breakpoints[3]}) {
          height: 760px;
        }
      }
    }

    .dropdown-btn {
      transform: rotate(0deg);
    }
  }
`

export default StyledCard
