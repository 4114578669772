import React from 'react'
import { Image, Text } from 'rebass'
import StyledValue from './style'

const Value = ({ description, icon, title }) => (
  <StyledValue mb={[0, 5, 5, 5]} mt={[6, 5, 5, 5]} px={2} width={[1, 1, 1 / 3, 1 / 3]}>
    <Image alt={icon.description} mb={2} mx="auto" src={icon.file.url} />
    <Text mb={2} fontSize={[1, 1, 1, 2]} fontWeight="700" width={1}>{title}</Text>
    <Text fontSize={[0, 0, 0, 1]} textAlign="center">{description}</Text>
  </StyledValue>
)

export default Value
