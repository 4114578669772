import React, { useState } from 'react'
import CountUp from 'react-countup'
import ReactMarkdown from 'react-markdown'
import VisibilitySensor from 'react-visibility-sensor'
import { Link } from 'gatsby'
import {
  Flex, Box, Text, Image
} from 'rebass'

import Heading from '../../elements/Heading'
import StyledCircle from '../../elements/StyledCircle'
import FadeIn from '../../elements/FadeIn'
import { getMarkdownLinkTarget } from '../../../__utils__/helpers'
import StyledWhyUsStudies from './style'

const WhyUsStudies = ({ ...studiesProps }) => {
  const [triggerPercentageCounter, setPercentageCounter] = useState(false)

  const percentageCounter = (isVisible) => {
    if (isVisible) {
      setPercentageCounter(true)
    }
  }

  return (
    <StyledWhyUsStudies mx="auto" px={[4, 4, 4, 4, 0]}>
      <Box pt={7}>
        <Heading fontSize={[4, 4, 4, 5]} postsymbol="">{studiesProps.simpleTitle}</Heading>
        <FadeIn>
          <Text className="eyebrow" fontSize={[1, 1, 1, 2]} fontWeight="300" mt={5}>
            {studiesProps.simpleSubtitle}
          </Text>
        </FadeIn>
      </Box>
      <Box className="simplicity-studies" my={[5, 5, 7, 7]} px={[5, 5, 0, 0]} py={[5]} mx="auto" width={1}>
        <Box mx="auto" width={[1, 1, 5 / 8, 5 / 8]}>
          <Heading fontSize={[3, 3, 3, 4]} fontWeight="700" is="h3" mb={4} postsymbol="">{studiesProps.statsTitle}</Heading>
          <VisibilitySensor onChange={percentageCounter}>
            <Flex flexWrap="wrap" justifyContent="space-between">
              <Box width={[1, 1, 3 / 8, 3 / 8]}>
                <CountUp
                  className="counter"
                  duration={2}
                  end={triggerPercentageCounter ? parseInt(studiesProps.statsLeftNumber, 10) : 0}
                  suffix="%"
                />
                <Text fontSize={[0, 0, 0, 1]} fontWeight="700" mb={4}>{studiesProps.statsLeftText}</Text>
              </Box>
              <Box width={[1, 1, 3 / 8, 3 / 8]}>
                <CountUp
                  className="counter"
                  duration={2}
                  end={triggerPercentageCounter ? parseInt(studiesProps.statsRightNumber, 10) : 0}
                  suffix="%"
                />
                <Text fontSize={[0, 0, 0, 1]} fontWeight="700">{studiesProps.statsRightText}</Text>
              </Box>
            </Flex>
          </VisibilitySensor>
          <Text textAlign="center">
            <ReactMarkdown source={studiesProps.statsFootnote} linkTarget={getMarkdownLinkTarget} />
          </Text>
        </Box>
      </Box>
      <Box my={[5, 5, 7, 7]}>
        <Heading fontSize={[3, 3, 4, 4]} is="h3" mb={5} postsymbol="">{studiesProps.simplifyTitle}</Heading>
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Box width={[1, 1, 1, 15 / 32]} mb={[4, 4, 4, 0]}>
            <Heading fontSize={[1, 1, '28px', '28px']} fontWeight="900" lineHeight={['32px']} mb={2} postsymbol="" is="h4">{studiesProps.simplifyColumnLeftTitle}</Heading>
            <Text fontSize={[1, 1, 2, 2]}>{studiesProps.simplifyColumnLeftText}</Text>
          </Box>
          <Box width={[1, 1, 1, 15 / 32]}>
            <Heading fontSize={[1, 1, '28px', '28px']} fontWeight="900" mb={2} postsymbol="" is="h4">{studiesProps.simplifyColumnRightTitle}</Heading>
            <Text fontSize={[1, 1, 2, 2]}>{studiesProps.simplifyColumnRightText}</Text>
            <Box className="cta">
              <Link to={studiesProps.simplifyColumnRightLinkPath} data-test-id="simplify-right-column-link">
                <Flex alignItems="center" mt={3}>
                  <StyledCircle mr="20px" rightarrow>
                    <Image alt="right arrow icon" src="/images/downarrow.svg" />
                  </StyledCircle>
                  <Text className="link-text">{studiesProps.simplifyColumnRightLinkText}</Text>
                </Flex>
              </Link>
            </Box>
          </Box>
        </Flex>
      </Box>
    </StyledWhyUsStudies>
  )
}

export default WhyUsStudies
