import styled from 'styled-components'
import { Box } from 'rebass'

const StyledWhyUsTeam = styled(Box)`
  max-width: 1168px;
  transform-style: preserve-3d;

  h2 {
    letter-spacing: -2.75px;
    
    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      letter-spacing: -5px;
    }
  }

  .team-intro {
    margin: 0 auto;
    max-width: 1077px;
  }

  .team-members {
    transform-style: preserve-3d;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    justify-content: space-evenly;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      margin-left: auto;
      margin-right: auto;
      max-width: 600px;
    }

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      justify-content: normal;
      max-width: 940px;
    }

    @media (min-width: ${props => props.theme.breakpoints[3]}) {
      max-width: 100%;
    }

    .team-member {
        transition: 0.2s cubic-bezier(.39,.575,.565,1);

        &:nth-of-type(1) {
          margin-right: -20px;
          margin-left: -20px;

          &.hovered {
            margin-right: -20px;
            margin-left: -20px;
          }

          @media (min-width: ${props => props.theme.breakpoints[2]}) {
            margin-left: 0px;

            &.hovered {
              margin-left: 0px;
              margin-right: -20px;
            }
          }
        }

        &:nth-of-type(2) {
          margin-right: -40px;
          margin-top: -15px;
          transform: translate3d(0, 0, 400px) scale(1);

          &.hovered {
            margin-right: -20px;
            margin-left: -20px;
          }

          @media (min-width: 374px) {
            margin-right: -60px;

            &.hovered {
              margin-right: -34px;
            }
          }

          @media (min-width: ${props => props.theme.breakpoints[1]}) {
            margin-right: -40px;

            &.hovered {
              margin-right: -40px;
            }
          }

          @media (min-width: ${props => props.theme.breakpoints[2]}) {
            margin-top: 125px;
            margin-left: -20px;

            &.hovered {
              margin-left: -20px;
            }
          }

          @media (min-width: ${props => props.theme.breakpoints[3]}) {
            margin-right: -46px;

            &.hovered {
              margin-right: -46px;
            }
          }
        }

        &:nth-of-type(3) {
          margin-top: -15px;
          transform: translate3d(0, 0, 0px) scale(1);

          &.hovered {
            margin-right: -20px;
            margin-left: -20px;
          }

          @media (min-width: ${props => props.theme.breakpoints[2]}) {
            margin-top: 30px;
            margin-left: -46px;
            margin-right: -46px;

            &.hovered {
              margin-left: -46px;
              margin-right: -46px;
            }
          }
          
          @media (min-width: ${props => props.theme.breakpoints[3]}) {
            margin-top: 30px;
            margin-left: 0;
            margin-right: 0;

            &.hovered {
              margin-left: 0px;
              margin-right: 0px;
            }
          }
        }

        &:nth-of-type(4) {
          margin-left: 0;
          margin-top: -15px;
          transform: translate3d(0, 0, 400px) scale(1);

          &.hovered {
            margin-right: -20px;
            margin-left: -20px;
          }

          @media (min-width: 374px) {
            margin-left: -60px;

            &.hovered {
              margin-left: -34px;
            }
          }

          @media (min-width: ${props => props.theme.breakpoints[1]}) {
            &.hovered {
              margin-left: -60px;
            }
          }

          @media (min-width: ${props => props.theme.breakpoints[2]}) {
            margin-top: -65px;
            margin-left: 0px;
            margin-right: -46px;

            &.hovered {
              margin-left: 0px;
              margin-right: -46px;
            }
          }
        }

        &:nth-of-type(5) {
          margin-right: -40px;
          margin-top: -15px;
          transform: translate3d(0, 0, 200px) scale(1);

          &.hovered {
            margin-right: -20px;
            margin-left: -20px;
          }

          @media (min-width: 374px) {
            margin-right: -60px;

            &.hovered {
              margin-right: -34px;
            }
          }

          @media (min-width: ${props => props.theme.breakpoints[1]}) {
            &.hovered {
              margin-right: -40px;
            }
          }

          @media (min-width: ${props => props.theme.breakpoints[2]}) {
            margin-top: 50px;
            margin-right: -46px;
            margin-left: 0px;
            z-index: 1;

            &.hovered {
              margin-left: 0px;
              margin-right: -46px;
            }
          }
        }

        &:nth-of-type(6) {
          margin-top: -15px;
          transform: translate3d(0, 0, 0px) scale(1);

          &.hovered {
            margin-right: -20px;
            margin-left: -20px;
          }

          @media (min-width: ${props => props.theme.breakpoints[1]}) {
            margin-top: -30px;

            &.hovered {
              margin-left: -46px;
              margin-right: -46px;
            }
          }

          @media (min-width: ${props => props.theme.breakpoints[3]}) {
            margin-top: -30px;

            &.hovered {
              margin-left: 0;
              margin-right: -46px;
            }
          }
        }

        &:nth-of-type(7) {
          margin-left: -40px;
          margin-top: -15px;
          transform: translate3d(0, 0, 0px) scale(1);

          &.hovered {
            margin-right: -20px;
            margin-left: -20px;
          }

          @media (min-width: 374px) {
            margin-left: -60px;

            &.hovered {
              margin-left: -34px;
            }
          }

          @media (min-width: ${props => props.theme.breakpoints[1]}) {
            &.hovered {
              margin-left: -60px;
            }
          }

          @media (min-width: ${props => props.theme.breakpoints[2]}) {
            margin-left: 100px;
            margin-right: -46px;
            margin-top: -90px;

            &.hovered {
              margin-left: 100px;
              margin-right: -46px;
            }
          }
        }

        &:nth-of-type(8) {
          margin-top: -15px;
          transform: translate3d(0, 0, 400px) scale(1);

          &.hovered {
            margin-right: -20px;
            margin-left: -20px;
          }

          @media (min-width: ${props => props.theme.breakpoints[2]}) {
            margin-top: -90px;
            margin-left: 150px;

            &.hovered {
              margin-left: 150px;
              margin-right: -46px;
            }
          }
        }

        &:hover {
          @media (min-width: ${props => props.theme.breakpoints[3]}) {
            transform: translate3d(0, 0, 800px) scale(1.05);
          }
        }

        &.hovered {
          transform: translate3d(0, 0, 800px) scale(1.05);
        }
      }
    }
  }
`

export default StyledWhyUsTeam
