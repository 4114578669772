import React from 'react'
import { graphql } from 'gatsby'
import { Text } from 'rebass'
import styled, { ThemeProvider } from 'styled-components'
import 'react-tabs/style/react-tabs.css'

import Helmet from '../components/Helmet'
import Header from '../components/blocks/Header'
import Footer from '../components/blocks/Footer'
import WhyUsHero from '../components/blocks/WhyUsHero'
import WhyUsTabs from '../components/blocks/WhyUsTabs'
import WhyUsStudies from '../components/blocks/WhyUsStudies'
import WhyUsTeam from '../components/blocks/WhyUsTeam'
import WhyUsJobs from '../components/blocks/WhyUsJobs'
import WhyUsWorkedWith from '../components/blocks/WhyUsWorkedWith'
import ScrollAnimationWrapper from '../components/elements/ScrollAnimationWrapper'
import useImageBrightness from '../hooks/useImageBrightness'
import theme from '../themes'

const StyledWhyUsPage = styled.div`
  font-weight: 300;
  pointer-events: auto !important;
  position: relative;
  transition: 0.5s cubic-bezier(0.45, 0, 0.55, 1);

  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  a,
  i,
  p,
  span {
    transition: 0.3s cubic-bezier(0.45, 0, 0.55, 1);
  }

  &::after {
    background: #373737;
    bottom: 0;
    content: '';
    display: flex;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: 0.5s cubic-bezier(0.45, 0, 0.55, 1);
    width: 100%;
    z-index: -1;
  }

  &.aos-animate {
    &::after {
      opacity: 1;
    }

    div,
    h1,
    h2,
    h3,
    h4,
    h5,
    a,
    i,
    p {
      color: white;
    }

    .card {
      &::after {
        opacity: 1;
      }

      button {
        background: #0060ff;
      }

      &:hover {
        @media (min-width: ${props => props.theme.breakpoints[2]}) {
          background: #fff;

          &::after {
            opacity: 0;
            transition: 0.3s cubic-bezier(0.45, 0, 0.55, 1) !important;
          }

          .card-content {
            div,
            h3,
            p {
              color: #000 !important;
            }
          }
        }
      }
    }

    .card-bg {
      background: #373737;

      &::after {
        border-color: #373737 transparent transparent transparent;
      }
    }

    .eyebrow {
      position: relative;
      padding-top: 2.5rem;

      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        border-radius: 100vw;
        background: #fff;
        width: 40px;
        height: 9px;
        transform: translate(0, -50%);
      }
    }

    .logo-slider {
      &:after,
      &:before {
        background: #373737;
      }
    }

    .quote-logo {
      filter: saturate(100%) grayscale(100%) invert(100%);
    }
  }

  a {
    color: ${props => props.theme.colors.primary};

    &:hover {
      color: ${props => props.theme.colors.linkHover};
    }

    &:active {
      color: ${props => props.theme.colors.linkActive};
    }
  }

  .arrow {
    background: #fff;
    border-radius: 50%;
    bottom: 50px;
    height: 50px;
    width: 50px;

    &.down {
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }

    img {
      margin: 20px auto;
    }
  }

  .eyebrow {
    position: relative;
    padding-top: 2.5rem;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      border-radius: 100vw;
      background: #ff7a7a;
      width: 40px;
      height: 9px;
      transform: translate(0, -50%);
    }
  }

  ${Text} {
    white-space: pre-line;
  }
`

const WhyUsPage = ({ data, location }) => {
  const query = data.contentfulWhyUsPage
  const { metaTitle, metaDescription, metaFeaturedImage } = query.seoMetadata

  const studiesProps = {
    simpleTitle: query.simpleAdvantageTitle,
    simpleSubtitle: query.simpleAdvantageSubtitle.simpleAdvantageSubtitle,
    statsTitle: query.statisticsTitle,
    statsLeftNumber: query.statisticsColumnLeftNumber,
    statsLeftText: query.statisticsColumnLeftText.statisticsColumnLeftText,
    statsRightNumber: query.statisticsColumnRightNumber,
    statsRightText: query.statisticsColumnRightText.statisticsColumnRightText,
    statsFootnote: query.statisticsFootnote.statisticsFootnote,
    simplifyTitle: query.simplifyTitle,
    simplifyColumnRightTitle: query.simplifyColumnRightTitle,
    simplifyColumnRightText: query.simplifyColumnRightText.simplifyColumnRightText,
    simplifyColumnRightLinkPath: query.simplifyColumnRightLinkPath,
    simplifyColumnRightLinkText: query.simplifyColumnRightLinkText,
    simplifyColumnLeftTitle: query.simplifyColumnLeftTitle,
    simplifyColumnLeftText: query.simplifyColumnLeftText.simplifyColumnLeftText
  }

  const HeroBgSrc = query.heroBackground.file.url
  const heroImageBrightness = useImageBrightness(HeroBgSrc)

  return (
    <ThemeProvider theme={theme}>
      <Helmet
        title={metaTitle}
        description={metaDescription.metaDescription}
        metaImage={metaFeaturedImage && metaFeaturedImage.file.url}
        canonical="https://powershifter.com/"
      />
      <ScrollAnimationWrapper>
        <StyledWhyUsPage
          data-aos="dark-mode"
          data-aos-anchor=".dark-mode-trigger"
          data-aos-mirror="true"
          data-aos-duration="300"
        >
          <Header variant="transparent" hasLightBackground={heroImageBrightness > 150} />
          <WhyUsHero
            heroBg={query.heroBackground}
            heroBgMobile={query.heroBackgroundMobile}
            heroTitle={query.heroTitle}
            heroSubtitle={query.heroSubtitle.heroSubtitle}
            whyUsTitle={query.whyUsTitle}
            whyUsColumnLeftText={query.whyUsColumnLeftText.whyUsColumnLeftText}
            whyUsColumnRightText={query.whyUsColumnRightText.whyUsColumnRightText}
            whyUsFootnote={query.whyUsFootnote.whyUsFootnote}
          />
          <WhyUsTabs tabs={query.tabs} location={location} />
          <WhyUsWorkedWith workedWithLogos={query.workedWithLogos} />
          <WhyUsStudies {...studiesProps} />
          <WhyUsTeam
            teamTitle={query.teamTitle}
            teamSubtitle={query.teamSubtitle.teamSubtitle}
            teamMembers={query.teamMembers}
          />
          <WhyUsJobs title={query.careersTitle} subtitle={query.careersSubtitle} />
          <Footer />
        </StyledWhyUsPage>
      </ScrollAnimationWrapper>
    </ThemeProvider>
  )
}

export default WhyUsPage

export const query = graphql`
  {
    contentfulWhyUsPage {
      heroBackground {
        file {
          url
        }
      }
      heroBackgroundMobile {
        file {
          url
        }
      }
      heroSubtitle {
        heroSubtitle
      }
      heroTitle
      simpleAdvantageSubtitle {
        simpleAdvantageSubtitle
      }
      simpleAdvantageTitle
      statisticsColumnLeftNumber
      statisticsColumnLeftText {
        statisticsColumnLeftText
      }
      statisticsColumnRightNumber
      statisticsColumnRightText {
        statisticsColumnRightText
      }
      statisticsFootnote {
        statisticsFootnote
      }
      statisticsTitle
      simplifyTitle
      simplifyColumnRightTitle
      simplifyColumnRightText {
        simplifyColumnRightText
      }
      simplifyColumnRightLinkText
      simplifyColumnRightLinkPath
      simplifyColumnLeftTitle
      simplifyColumnLeftText {
        simplifyColumnLeftText
      }
      seoMetadata {
        metaDescription {
          metaDescription
        }
        metaFeaturedImage {
          file {
            url
          }
          description
        }
        metaTitle
      }
      teamTitle
      whyUsColumnLeftText {
        whyUsColumnLeftText
      }
      whyUsColumnRightText {
        whyUsColumnRightText
      }
      whyUsFootnote {
        whyUsFootnote
      }
      whyUsTitle
      workedWithTitle
      workedWithLogos {
        file {
          url
        }
        description
      }
      teamSubtitle {
        teamSubtitle
      }
      teamMembers {
        id
        bio {
          bio
        }
        name
        jobTitle
        quote
        thumbnail {
          description
          fluid(quality: 75) {
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          file {
            url
          }
        }
      }
      tabs {
        id
        tabButtonText
        tabCaseStudies {
          id
          caseStudyClass
          caseStudyLinkPath
          caseStudyLinkText
          caseStudyName
          caseStudyImage {
            file {
              url
            }
            description
          }
        }
        tabDescription {
          tabDescription
        }
        tabDescriptionSplitLeft {
          tabDescriptionSplitLeft
        }
        tabDescriptionSplitRight {
          tabDescriptionSplitRight
        }
        tabTestimonialJobTitle
        tabTestimonialLogo {
          file {
            url
          }
          description
        }
        tabTestimonialLinkText
        tabTestimonialLinkPath
        tabTestimonialName
        tabTestimonialQuote {
          tabTestimonialQuote
        }
        tabTestimonialThumbnail {
          description
          file {
            url
          }
        }
        tabTitle
        tabValues {
          id
          title
          subtitle
          icon {
            description
            file {
              url
            }
          }
        }
        tabCardsTitle
        tabCardsSubtitle {
          tabCardsSubtitle
        }
        tabCards {
          id
          subtitle {
            subtitle
          }
          title
          serviceLength
          icon {
            file {
              url
            }
            description
          }
          cardDetails {
            id
            cardLabel
            cardDetail
          }
        }
      }
      careersTitle
      careersSubtitle
    }
  }
`
