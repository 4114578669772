import styled, { css } from 'styled-components'
import { Box } from 'rebass'

export const StyledWhyUsTabs = styled(Box)`
  max-width: 1125px;
  padding-top: 0px;
  position: relative;

  .react-tabs {
    width: 100%;
  }

  .why-us-tabs-wrapper {
    display: flex;
    justify-content: center;
    left: 0;
    top: 81px;
    transition-property: transform, padding;
    transition-duration: 0.2s, 0.3s !important;
    transition-timing-function: cubic-bezier(0.45, 0, 0.55, 1);
    max-width: none;
    padding: 0 32px;
    position: absolute;
    width: 100%;
    z-index: 5;

    &.scrolled-past {
      padding: 0;
      margin: 0;
      position: fixed;
      transform: scale(1) translateY(-200px);

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        top: 90px;
        transform: scale(0.75) translateY(-200px);
      }
    }

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      padding: 0 20px;
      top: 154px;
      transform: scale(1);
    }

    @media (min-width: ${props => props.theme.breakpoints[3]}) {
      padding: 0;
    }
  }

  .why-us-tabs {
    background: white;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0);
    font-weight: 700;
    margin: 0;
    max-width: 1102px;
    overflow: hidden;
    padding: 0;
    position: relative;
    pointer-events: auto !important;
    width: 100%;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      border: 2px solid #0060ff;
      border-radius: 34px;
      max-width: 704px;
    }

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      max-width: 1102px;
    }

    .react-tabs__tab {
      background: none;
      border: none;
      bottom: 0px;
      box-sizing: border-box;
      color: #4e90ff;
      display: inline-block;
      font-size: 12px;
      line-height: 12px;
      font-weight: 900;
      height: 100%;
      line-height: 30px;
      padding: 13px;
      position: relative;
      text-align: center;
      transition: 0.2s cubic-bezier(0.45, 0, 0.55, 1);
      width: 33.3333333%;

      @media (min-width: 374px) {
        padding: 13px 5px;
      }

      span {
        color: #4e90ff;
        display: block;
        font-size: 12px;
        letter-spacing: -0.5px;
        line-height: 12px;
      }

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        color: #0060ff;
        width: 100%;

        span {
          display: inline-block;
          font-size: 20px;
          letter-spacing: 0px;
          line-height: 20px;
        }
      }

      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        span {
          font-size: 24px;
          line-height: 24px;
        }
      }

      &:first-of-type {
        &::after {
          content: none;
        }
      }

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        border-radius: 32px;
        font-size: 20px;
        line-height: 30px;
        padding: 17px 12px;
        text-align: center;
        width: 33.33333333%;
      }

      @media (min-width: ${props => props.theme.breakpoints[2]}) {
        font-size: 24px;
      }

      &:hover {
        @media (min-width: ${props => props.theme.breakpoints[2]}) {
          opacity: 0.5;
        }
      }
    }

    .react-tabs__tab--selected {
      color: #0060ff;

      span {
        color: #0060ff;

        @media (min-width: ${props => props.theme.breakpoints[1]}) {
          color: #fff;
        }
      }

      &::after {
        opacity: 0;
      }
    }

    .react-tabs__tab--selected + .react-tabs__tab {
      &::after {
        opacity: 0;
      }
    }
  }

  .react-tabs__tab-panel {
    padding-top: 220px;
    h3 {
      letter-spacing: normal;
      & + p {
        margin-top: 0;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      padding-top: 297px;
    }
  }

  .react-tabs__tab-panel--selected {
    animation: tabFadeIn 0.5s cubic-bezier(0.45, 0, 0.55, 1);
  }

  .tabs-wrapper {
    width: 100%;
  }

  @keyframes tabFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  [data-aos='fixedTabs'] {
    &.aos-animate {
      margin: 0;
      padding: 0;
      position: fixed;
      top: 81px;
      transition-timing-function: cubic-bezier(0.45, 0, 0.55, 1);
      transform: scale(1);
      width: 100%;

      .sliding-bg {
        border-radius: 0 0 18px 18px;

        &.center {
          border-radius: 0;

          @media (min-width: ${props => props.theme.breakpoints[1]}) {
            border-radius: 32px;
          }
        }

        @media (min-width: ${props => props.theme.breakpoints[1]}) {
          border-radius: 32px;
        }
      }

      .why-us-tabs {
        border: 0;
        border-radius: 0 0 10px 10px;
        box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);

        @media (min-width: ${props => props.theme.breakpoints[1]}) {
          border: 2px solid #0060ff;
          border-radius: 34px;
        }
      }

      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        top: 90px;
        transform: scale(0.75);
      }
    }
  }
`

export const SlidingBG = styled('div')`
  background: #fff;
  border-top: 4px solid #0060ff;
  height: 100%;
  max-height: 46px;
  position: absolute;
  top: 0;
  transition: 0.2s cubic-bezier(0.45, 0, 0.55, 1);
  width: 100%;
  width: 33.333333333%;
  z-index: 0;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    background: #0060ff;
    border-top: 0;
    border-radius: 0 0 18px 18px;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
    max-height: none;
    width: 33.33333333%;
  }

  ${props => props.position === 0 && css`
    top: 0;
    left: 0;

    @media (min-width: ${props.theme.breakpoints[1]}) {
      border-radius: 34px;
      left: 0;
      top: auto;
    }
  `}

  ${props => props.position === 1 && css`
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: ${props.theme.breakpoints[1]}) {
      border-radius: 34px;
    }
  `}

  ${props => props.position === 2 && css`
    top: 0;
    left: 100%;
    transform: translateX(-100%);

    @media (min-width: ${props.theme.breakpoints[1]}) {
      border-radius: 34px;
      left: 100%;
      top: auto;
      transform: translateX(-100%);
    }
  `}
`


export const DarkMode = styled('div')`
  height: 1750px;
  position: absolute;
  top: -150px;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    height: 1550px;
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
  height: 550px;
  }
`
