import React from 'react'
import { Link } from 'gatsby'
import {
  Flex, Box, Image, Text
} from 'rebass'
import FadeIn from '../FadeIn'
import StyledCircle from '../StyledCircle'
import StyledDigitalProduct from './style'

const DigitalProduct = ({
  caseStudyClass, caseStudyImage, caseStudyCta, caseStudyPath
}) => (
  <StyledDigitalProduct>
    <Box className={caseStudyClass} mb={[0, 0, 5, 5]} mt={[5, 5, 7, 7]}>
      <Box mx="auto">
        <Link
          id={`${caseStudyClass}-case-study`}
          to={caseStudyPath}
        >
          <Image alt={caseStudyImage.description} className="product" mx="auto" src={caseStudyImage.file.url} />
        </Link>
        <FadeIn>
          <Box className="cta-wrapper" mt={3}>
            <Link
              id={`${caseStudyClass}-case-study-cta`}
              to={caseStudyPath}
            >
              <Flex justifyContent="center">
                <Box>
                  <StyledCircle rightarrow>
                    <Image alt="right arrow icon" src="/images/downarrow.svg" />
                  </StyledCircle>
                </Box>
                <Box ml={3} alignSelf="center">
                  <Text className="leftbrow" color="#000" fontSize={0} fontWeight="700">{caseStudyCta}</Text>
                </Box>
              </Flex>
            </Link>
          </Box>
        </FadeIn>
      </Box>
    </Box>
  </StyledDigitalProduct>
)

export default DigitalProduct
