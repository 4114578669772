import styled from 'styled-components'
import { Flex, Box, Text } from 'rebass'

export const JobsContainer = styled(Box)`
  margin: 0 auto;
  max-width: 1077px;
`

export const Job = styled(Flex)`
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
`

export const MobileJobMeta = styled(Text)`
  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    display: none;
  }
`

export const DesktopJobMeta = styled(Text)`
  display: none;
  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    display: block;
  }
`
