import styled from 'styled-components'
import { Box } from 'rebass'

const StyledWhyUsProduct = styled(Box)`
  color: #000;

  * {
    color: #000;
  }

  h2 {
    letter-spacing: -2px;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      letter-spacing: -2.75px;
    }
  }

  .more-reviews {
    margin: 0 auto;
  }

  .quote-logo {
    height: auto;
    max-height: 106px;
    max-width: 223px;
    width: auto;
  }

  .eyebrow p:first-child {
    margin-top: 0;
  }
`

export default StyledWhyUsProduct
