import { useState, useEffect } from 'react'
import { getImageBrightness } from '../__utils__/helpers'

export default (image) => {
  const [brightness, setBrightness] = useState(0)

  useEffect(() => {
    getImageBrightness(image, setBrightness)
  }, [image])

  return brightness
}
