import styled from 'styled-components'
import { Box } from 'rebass'

export const StyledWhyUsCards = styled(Box)`
  position: relative;
  max-width: 1125px;

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    max-height: 968px;
  }

  .card-bg {
    background: #0060FF;
    height: 100%;
    left: 50%;
    max-width: none;
    min-width: 1920px;
    position: absolute;
    top: 0;
    transform: translate(-50%);
    transition: 0.5s cubic-bezier(0.45, 0, 0.55, 1);
    z-index: -1111;

    &:after {
      border-color: #0060FF transparent transparent transparent;
      border-style: solid;
      border-width: 70px 960px 0 960px;
      bottom: -70px;
      content: '';
      height: 0;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      transition: 0.5s cubic-bezier(0.45, 0, 0.55, 1);
      width: 0;
    }
  }

  .content {
    max-width: 1125px;
  }

  .dark-mode-trigger {
    height: 1750px;
    position: absolute;
    top: -150px;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      height: 1550px;
    }

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      height: 550px;
    }
  }

  h2,
  div {
    color: #fff;
  }

  h2 {
    letter-spacing: -2.75px;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      letter-spacing: -5px;
    }
  }
`

export const LearnMoreScrollAnchor = styled.div`
  position: absolute;
  top: -120px;
  left: 0;
  width: 100%;
  height: 1px;
`

export default StyledWhyUsCards
