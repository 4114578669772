import React from 'react'
import styled from 'styled-components'
import { Flex } from 'rebass'
import CircleStyle from '../../elements/StyledCircle'

export const StyledWhyUsHero = styled.div`
  .color-break {
    background: #0060FF;
    position: relative;

    &:after {
      content: '';
      border-color: #0060FF transparent transparent transparent;
      border-style: solid;
      border-width: 70px 960px 0 960px;
      height: 0;
      width: 0;
      position: absolute;
      bottom: -70px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  ${CircleStyle} {
    transform: none;
  }

  .content {
    max-width: 1168px;
  }

  h1,
  h2,
  div {
    color: #fff;
  }

  h1 {
    letter-spacing: -4.12px;
    line-height: 60px;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      letter-spacing: -9px;
      line-height: 140px;
    }
  }

  .masthead {
    min-height: 500px;
    position: relative;
    transition: 0s;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      min-height: 778px;
    }
  }

  p {
    margin: 0;
  }

  strong {
    font-weight: 900;
  }
`

export const Masthead = styled(({ heroBg, heroBgMobile, ...rest }) => <Flex {...rest} />)`
  background: url('${props => props.heroBgMobile.file.url}') center center/cover no-repeat;
  box-sizing: content-box;
  min-height: 500px;
  position: relative;
  transition: 0s;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    background: url('${props => props.heroBg.file.url}') center center/cover no-repeat;
    min-height: 778px;
  }
`
