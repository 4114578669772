import React from 'react'
import Slider from 'react-slick'
import LazyLoad from 'react-lazy-load'
import { Image } from 'rebass'
import Heading from '../../elements/Heading'
import StyledWhyUsWorkedWith from './style'

const AnimSettings = {
  centerMode: true,
  arrows: false,
  draggable: false,
  speed: 1000,
  pauseOnHover: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 1250,
  responsive: [
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1
      }
    }
  ]
}

const WhyUsWorkedWith = ({ workedWithLogos }) => (
  <StyledWhyUsWorkedWith mx="auto">
    <Heading is="h3" fontSize={[3, 3, 3, '54px']} fontWeight="900" mb={5} postsymbol="" px={[4, 4, 0, 0]}>Who we've worked with</Heading>
    <LazyLoad debounce offsetVertical={500} throttle={0}>
      <Slider className="logo-slider" {...AnimSettings}>
        {workedWithLogos.map(logo => (
          <Image
            alt={logo.description}
            src={logo.file.url}
            key={logo.file.url}
          />
        ))}
      </Slider>
    </LazyLoad>
  </StyledWhyUsWorkedWith>
)

export default WhyUsWorkedWith
