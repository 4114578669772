import React from 'react'
import styled, { css } from 'styled-components'
import { StyledButton } from '../Button'

const StyledCircle = styled(({ dark, rightarrow, ...props }) => <StyledButton {...props} />)`
  align-items: center;
  background-color: white;
  border-radius: 100vw;
  border: none;
  box-shadow: 2px 5px 30px 0px rgba(0,0,0,0.3);
  display: flex;
  padding: 0;
  height: 50px;
  justify-content: center;
  min-width: 50px;

  &:hover {
    background-color: #e0e0e0;
    border: none;
  }

  &:after,
  &:before {
    background-color: rgba(0,0,0,0.05);
  }

  ${props => props.dark && css`
    background: #373737;
  `};

  ${props => props.rightarrow && css`
    img {
      margin: 0 auto;
      padding-top: 3px;
      transform: rotate(270deg);
    }
  `};
`

export default StyledCircle
