import styled from 'styled-components'
import { Box, Flex } from 'rebass'

const StyledTeamMember = styled(Box)`
  border-radius: 20px;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.2);
  height: 385px;
  max-width: 290px;
  position: relative;
  text-align: center;
  transition: 0.1s cubic-bezier(0.45, 0, 0.55, 1);
  overflow: hidden;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    border-radius: 30px;
    height: 550px;
    min-width: 417px;
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    margin: 0 -46px;
    height: 615px;
    min-width: 355px;
  }

  @media (min-width: ${props => props.theme.breakpoints[3]}) {
    height: 550px;
    margin: 0;
    min-width: 417px;
  }

  div {
    color #000;
  }

  ${Flex} {
    height: 100%;
  }

  .team-anchor {
    position: absolute;
    top: -75px;
    left: 0px;
    width: 100%;
    margin-bottom: 0;
  }

  .bio {
    line-height: 20px;
    font-weight: 300;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .thumbnail {
    height: 100%;
    left: 0;
    position: absolute !important;
    top: 0;
    width: 100%;
    z-index: -1
  }

  h3,
  h4 {
    color: #fff;
    letter-spacing: -2px;
    text-align: left;
    width: 100%;
  }

  .hidden-content {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 5px;
    height: calc(100% - 30px);
    left: 15px;
    padding: 20px;
    position: absolute;
    opacity: 0;
    text-align: left;
    top: 15px;
    width: calc(100% - 30px);

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      height: calc(100% - 60px);
      left: 20px;
      top: 30px;
      width: calc(100% - 40px);
    }

    h3,
    h4 {
      color: #000;
    }

    h3 {
      letter-spacing: -2px;
    }

    h4 {
      letter-spacing: -1.5px;
    }
  }

  &.hovered {
    box-sizing: border-box;
    height: 464px;
    max-width: 325px;
    width: 325px;

    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      height: 550px;
    }

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      height: 615px;
      .hidden-content {
        opacity: 1;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints[3]}) {
      height: 550px;
    }

    @media (max-width: ${props => props.theme.breakpoints[2]}) {
      .hidden-content {
        opacity: 1;
      }
    }
  }
`

export default StyledTeamMember
