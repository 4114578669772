import styled from 'styled-components'
import { Box } from 'rebass'

const StyledValue = styled(Box)`
  text-align: center;
  transition: 0.3s cubic-bezier(0.45, 0, 0.55, 1);

  &:hover {
    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      transform: scale(1.025);
    }
  }

  h3,
  h4 {
    color: #373737;
    font-weight: 700;
  }

  p {
    color: #373737;
  }

  &:nth-of-type(2) {
    .value-fade-in {
      transition-delay: 0.25s;
    }
  }

  &:nth-of-type(3) {
    .value-fade-in {
      transition-delay: 0.5s;
    }
  }

  &:nth-of-type(4) {
    .value-fade-in {
      transition-delay: 0.75s;
    }
  }

  &:nth-of-type(5) {
    .value-fade-in {
      transition-delay: 1s;
    }
  }

  &:nth-of-type(6) {
    .value-fade-in {
      transition-delay: 1.25s;
    }
  }
`

export default StyledValue
