import React from 'react'
import {
  Box, Flex, Heading, Image, Text
} from 'rebass'
import Modal from 'react-modal'
import Button from '../Button'
import StyledCard from './style'
import StyledCircle from '../StyledCircle'
import CardDetail from '../CardDetail'

const Card = ({
  cardOpen, cardDetails, icon, serviceLength, subtitle, title, onClick, isOpen
}) => (
  <Modal
    cardOpen={cardOpen}
    isOpen={isOpen}
    className="Modal"
    overlayClassName="Overlay"
    htmlOpenClassName="ReactModal__Html--open"
    shouldFocusAfterRender={false}
  >
    <StyledCard className="card open" onClick={onClick}>
      <Flex className="card-content" flexDirection="column">
        <StyledCircle className="dropdown-btn" dark="true" ml="auto" mr={0}>
          <Image
            alt="close icon"
            src="/images/close.svg"
          />
        </StyledCircle>
        <Box className="icon-wrapper" mb={3}>
          <Image alt={icon.description} mx="auto" src={icon.file.url} />
        </Box>
        <Box className="visible-content">
          <Heading fontSize={2} fontWeight="900" is="h3">{title}</Heading>
          <Heading fontSize={2} fontWeight="900" is="h3" mb={2}>{serviceLength}</Heading>
          <Text>{subtitle}</Text>
        </Box>
        <Box className="hidden-content visible" mt={3}>
          <Flex flexDirection="column" justifyContent="space-between">
            <Box>
              {cardDetails.map(cardDetail => (
                <CardDetail
                  cardLabel={cardDetail.cardLabel}
                  key={cardDetail.id}
                >
                  {cardDetail.cardDetail}
                </CardDetail>
              ))}
            </Box>
            <Box>
              <Button href="#bottom" mt={3}>
                Connect with us
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </StyledCard>
  </Modal>
)

export default Card
