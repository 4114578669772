import React from 'react'

/**
 * Returns a single instance of a component from an array, given the
 * Component Name.
 * Note: requires a non-sealed array; typically a cloned instance of a
 * children array.
 * @param {*} children
 * @param {*} componentName
 * @param {*} newProps
 */
// prettier-ignore
const takeFrom = (children, component, newProps = {}) => {
  for (let i = 0; i < children.length; i += 1) {
    const currentChild = children[i]
    if (currentChild.type === component.type) {
      const foundChild = currentChild
      children.splice(i, 1)
      return React.cloneElement(foundChild, newProps)
    }
  }

  return null
}

// prettier-ignore
const formatDate = (date) => {
  const d = new Date(date)
  const month = d.getMonth()
  const monthOffset = month + 1
  const day = d.getDate()

  const monthString = (monthOffset < 10) ? `0${monthOffset}` : monthOffset.toString()
  const dayString = (day < 10) ? `0${day}` : day.toString()
  const formattedDate = `${monthString}.${dayString}.${d.getFullYear()}`

  return formattedDate
}

const getImageBrightness = (imageSrc, callback) => {
  const img = document.createElement('img')
  img.crossOrigin = 'Anonymous'
  img.src = imageSrc
  img.style.display = 'none'
  document.body.appendChild(img)

  let colorSum = 0

  img.onload = function () {
    // create canvas
    const canvas = document.createElement('canvas')
    canvas.width = this.width
    canvas.height = this.height

    const ctx = canvas.getContext('2d')
    ctx.drawImage(this, 0, 0)

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
    const { data } = imageData
    let r; let g; let b; let avg

    for (let x = 0, len = data.length; x < len; x += 4) {
      r = data[x]
      g = data[x + 1]
      b = data[x + 2]

      avg = Math.floor((r + g + b) / 3)
      colorSum += avg
    }

    const brightness = Math.floor(colorSum / (this.width * this.height))
    callback(brightness)
  }
}

const toPairs = list => list.reduce((chunks, article, index) => (
  index % 2 === 0
    ? [...chunks, [article]]
    : [...chunks.slice(0, chunks.length - 1), [...chunks[chunks.length - 1], article]]
), [])

const getMarkdownLinkTarget = url => (url.indexOf('powershifter.com') === -1 ? '_blank' : '_self')

export {
  takeFrom as default,
  formatDate,
  getImageBrightness,
  toPairs,
  getMarkdownLinkTarget
}
