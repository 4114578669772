import React from 'react'
import PropTypes from 'prop-types'
import { Text, Box } from 'rebass'
import Heading from '../../elements/Heading'
import FadeIn from '../../elements/FadeIn'
import Button from '../../elements/Button'

import { JobsContainer } from './styles'

const WhyUsJobs = ({ title, subtitle }) => (
  <JobsContainer as="section" pt="80px" pb="100px" px={[4, 4, 4, 4, 0]}>
    <Heading fontSize={[4, 4, 4, 5]} postsymbol="">
      {title}
    </Heading>
    <FadeIn>
      <Text className="eyebrow" fontSize={[1, 1, 1, 2]} fontWeight="300" mt={5} mb={1}>
        {subtitle}
      </Text>
    </FadeIn>
    <Box mt={[4, 6, 6, 6]}>
      <a href="https://powershifter.applytojobs.ca/" target="_blank" rel="noreferrer">
        <Button>View Jobs</Button>
      </a>
    </Box>
  </JobsContainer>
)

WhyUsJobs.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
}

export default WhyUsJobs
