import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ReactMarkdown from 'react-markdown'
import {
  Flex, Box, Image, Text
} from 'rebass'
import FadeIn from '../../elements/FadeIn'
import Heading from '../../elements/Heading'
import StyledCircle from '../../elements/StyledCircle'
import { Masthead, StyledWhyUsHero } from './style'

const WhyUsHero = ({
  heroBg,
  heroBgMobile,
  heroSubtitle,
  heroTitle,
  whyUsColumnLeftText,
  whyUsColumnRightText,
  whyUsFootnote
}) => (
  <StyledWhyUsHero>
    <Masthead
      className="masthead"
      flexDirection="column"
      heroBg={heroBg}
      heroBgMobile={heroBgMobile}
      justifyContent="space-between"
      pt={['120px', null, null, null]}
    >
      <Box pb={[6, 6, '100px', '100px']} pt={[2, 2, '100px', '100px']} m="auto" px={[4, 4, 6, 6, 0]} width={[1, 1, 1, 1, 23 / 32]}>
        <div data-aos="fade-in-load">
          <Heading color="#fff" fontSize={['65px', '65px', '65px', '150px']} lineHeight={['55px', '55px', '55px', '130px']} is="h1" postsymbol="">{heroTitle}</Heading>
        </div>
      </Box>
      <Box mx="auto" mb="50px" width={11 / 16}>
        <AnchorLink offset="81" href="#partnership">
          <StyledCircle mx="auto">
            <Image alt="down arrow icon" src="/images/downarrow.svg" />
          </StyledCircle>
        </AnchorLink>
      </Box>
    </Masthead>
    <Box id="partnership" px={[4, 4, 6, 6, 0]} className="color-break">
      <Box className="content" pb={[0, 5, 7, 7]} pt={[5, 5, 7, 7]} mx="auto" width={[1, 1, 1, 1, 11 / 16]}>
        <Box mx="auto">
          <Heading color="#fff" fontSize={[3, 3, 4, 4]} mb={5} postsymbol="">{heroSubtitle}</Heading>
        </Box>
        <Flex flexWrap="wrap" mb={[4, 4, 5, 5]} justifyContent="space-between">
          <Box mb={[4, 4, 0, 0]} width={[1, 1, 15 / 32, 15 / 32]}>
            <FadeIn>
              <Text color="#fff" fontSize={[0, 0, 1, 1]}>
                <ReactMarkdown>
                  {whyUsColumnLeftText}
                </ReactMarkdown>
              </Text>
            </FadeIn>
          </Box>
          <Box width={[1, 1, 15 / 32, 15 / 32]}>
            <FadeIn delay="250">
              <Text color="#fff" fontSize={[0, 0, 1, 1]}>
                <ReactMarkdown>
                  {whyUsColumnRightText}
                </ReactMarkdown>
              </Text>
            </FadeIn>
          </Box>
        </Flex>
        <Box width={[1]}>
          <FadeIn delay="500">
            <Text color="#fff" fontSize={[0, 0, 1, 1]}>
              <ReactMarkdown>
                {whyUsFootnote}
              </ReactMarkdown>
            </Text>
          </FadeIn>
        </Box>
      </Box>
    </Box>
  </StyledWhyUsHero>
)

export default WhyUsHero
