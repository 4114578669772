import styled from 'styled-components'
import { Box } from 'rebass'

const StyledWhyUsStudies = styled(Box)`
  max-width: 1077px;

  a {
    color: #020202;
    font-weight: 700;
    text-decoration: none;
  }

  .counter {
    color: ${props => props.theme.colors.primary};
    font-size: 45px;
    line-height: 1.1;
    display: block;
    font-weight: 900;
    margin-bottom: 10px;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      font-size: 80px;
      line-height: 90px;
    }
  }

  div {
    color: #000;
  }

  h2 {
    letter-spacing: -2.75px;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      letter-spacing: -5px;
    }
  }

  h3 {
    @media (min-width: ${props => props.theme.breakpoints[1]}) {
      letter-spacing: -2.75px;
    }
  }

  h4 {
    letter-spacing: -1.5px;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      letter-spacing: -1.75px;
    }
  }

  .link-text {
    position: relative;
    padding-left: 2em;
  
    &:before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      border-radius: 100vw;
      background: #ff7a7a;
      width: 20px;
      height: 5px;
      transform: translate(0, -50%);
    }
  }

  .simplicity-studies {
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
    border-radius: 30px;

    h3 {
      color: ${props => props.theme.colors.primary};
      font-weight: 700;
      text-align: center;
      width: 100%;
    }

    p {
      margin-bottom: 0;
    }
  }
`

export default StyledWhyUsStudies
