import styled from 'styled-components'

const StyledDigitalProduct = styled.div`
  position: relative;

  a {
    text-decoration: none;
  }
  
  .product {
    filter: drop-shadow(0 20px 30px rgba(0, 0, 0, 0.17));
    height: auto;
    width: 100%;
  }

  .arrow {
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.17);
    img {
      margin: 0 auto;
      padding: 14.5px 0px;
    }
  }

  .cta-wrapper {
    text-align: center;
    
    a {
      display: inline-block;
    }
  }

  h3 {
    line-height: normal;
  }

  .leftbrow {
    position: relative;
    padding-left: 2rem;

    &:before {
      background: #ff7a7a;
      border-radius: 100vw;
      content: '';
      height: 5px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 20px;
    }
  }

  .placeholder {
    display: none;
  }
`
export default StyledDigitalProduct
