import styled from 'styled-components'
import { Box } from 'rebass'

const StyledWhyUsWorkedWith = styled(Box)`
  max-width: 1168px;
  padding-top: 150px;
  text-align: center;

  h3 {
    letter-spacing: -2px;

    @media (min-width: ${props => props.theme.breakpoints[2]}) {
      letter-spacing: -2.75px;
    }
  }

  .logo-slider {
    margin: 0 auto;
    position: relative;

    &:after {
      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        background: white;
        content: '';
        height: 100%;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.5s ease-in-out;
        width: 50px;
        z-index: 1;
      }
    }

    &:before {
      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        background: white;
        content: '';
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: 0.5s ease-in-out;
        width: 50px;
        z-index: 1;
      }
    }

    .slick-slide,
    .slick-track {
      transition: 0s;
    }

    .slick-slide {
      div {
        display: flex;
        height: 96px;
        align-items: center;
      }

      img {
        margin: auto;
        transform: scale(0.4);
        transform-origin: center;
        transition: 0.5s cubic-bezier(.39,.575,.565,1);
        transition-delay: 0.5s;
        height: auto !important;
        width: auto !important;
        max-height: 60px;
        max-width: 135px;
        filter: saturate(0%);
        opacity: 0.3;

        @media (min-width: ${props => props.theme.breakpoints[2]}) {
          max-height: 96px;
          max-width: 216px;
        }
      }

      &.slick-center {
        img {
          filter: saturate(100%);
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }
`

export default StyledWhyUsWorkedWith
