import React, { useState } from 'react'
import {
  Box, Flex, Heading, Image, Text
} from 'rebass'
import LazyLoad from 'react-lazy-load'
import Button from '../Button'
import StyledCard from './style'
import StyledCircle from '../StyledCircle'
import CardModal from '../CardModal'
import CardDetail from '../CardDetail'

const Card = ({
  title,
  subtitle,
  icon,
  cardDetails,
  serviceLength,
  testId
}) => {
  const [cardOpen, setCardOpen] = useState(false)

  const [modalOpen, setModalOpen] = useState(false)

  const toggleCardOpen = () => {
    if (window.innerWidth < 768) {
      setModalOpen(!modalOpen)
    } else {
      setCardOpen(!cardOpen)
    }
  }

  return (
    <StyledCard
      className={cardOpen ? 'card open' : 'card closed'}
      onClick={toggleCardOpen}
      data-test-id={testId && `why-us-card-${testId}`}
    >
      <Flex className="card-content" flexDirection="column">
        <StyledCircle className="dropdown-btn" dark ml="auto" mr={0}>
          <Image
            alt="up arrow icon"
            src="/images/uparrow-white.svg"
          />
        </StyledCircle>
        <Box className="icon-wrapper" mb={3}>
          <LazyLoad debounce offsetVertical={300} throttle={0}>
            <Image alt={icon.description} mx="auto" src={icon.file.url} />
          </LazyLoad>
        </Box>
        <Box className="visible-content">
          <Heading fontSize={2} fontWeight="900" is="h3">{title}</Heading>
          <Heading fontSize={2} fontWeight="900" is="h3" mb={2}>{serviceLength}</Heading>
          <Text>{subtitle}</Text>
        </Box>
        <Box className={`hidden-content ${cardOpen ? 'visible' : ''}`} mt={3}>
          <Flex flexDirection="column" justifyContent="space-between">
            <Box>
              {cardDetails.map(cardDetail => (
                <CardDetail
                  key={cardDetail.id}
                  cardLabel={cardDetail.cardLabel}
                >
                  {cardDetail.cardDetail}
                </CardDetail>
              ))}
            </Box>
            <Box>
              <Button href="#bottom" mt={3} data-test-id={testId && `why-us-card-${testId}-button`}>
                Connect with us
              </Button>
            </Box>
          </Flex>
        </Box>
      </Flex>
      <CardModal
        cardDetails={cardDetails}
        cardOpen={cardOpen}
        icon={icon}
        isOpen={modalOpen}
        onClick={toggleCardOpen}
        serviceLength={serviceLength}
        subtitle={subtitle}
        title={title}
      />
    </StyledCard>
  )
}

export default Card
